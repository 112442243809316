import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";

import Head from "../../components/head.js";

import Layout from "../../components/layout";

const Azarikh = () => {
  return (
    <Layout>
      <Head title="Azarikh" />
      <h3>Azarikh</h3>
      <h4>
        <FaGlobe />
        Malaysia
      </h4>

      <p>
        <OutboundLink href="mailto:azarikh01@gmail.com">Gmail</OutboundLink>
      </p>
      <p>
        <OutboundLink href="https://www.instagram.com/azarikh/">
          Instagram
        </OutboundLink>
      </p>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <img
          src="https://assets1.ello.co/uploads/asset/attachment/15366952/ello-optimized-7f6a0fa9.gif"
          alt="Azarikh art"
        />
        <img
          src="https://assets1.ello.co/uploads/asset/attachment/15366997/ello-optimized-0a8b781a.gif"
          alt="Azarikh art"
        />
      </div>
      <img
        src="https://assets1.ello.co/uploads/asset/attachment/14766729/ello-optimized-ada80dd9.jpg"
        alt="Azarikh art"
      />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img
          src="https://assets0.ello.co/uploads/asset/attachment/15155838/ello-optimized-cff03c81.gif"
          alt="Azarikh art"
        />
      </div>
    </Layout>
  );
};

export default Azarikh;
